import { trackFaqItemOpened } from '@getpopsure/analytics';
import classnames from 'classnames';
import Markdown from 'components/markdown';
import { SectionHeader } from 'components/sectionHeader';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { FAQPageJsonLd } from 'next-seo';
import chevronDownIcon from 'public/static/icons/chevron-down.svg';
import React, { Fragment, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './style.module.scss';

export interface QuestionInfo {
  question: string;
  answer: string | JSX.Element;
  questionId: number;
}

export interface QuestionSection {
  title?: string;
  questionInfo: QuestionInfo[];
}

export interface QuestionJsonLd {
  questionName: string;
  acceptedAnswerText: string;
}

interface Props {
  questions: QuestionSection[];
  faqJsonLd: QuestionJsonLd[];
  children?: React.ReactNode;
  markdownClassName?: string;
}

const FAQSection = ({
  questions,
  children,
  faqJsonLd,
  markdownClassName,
}: Props): JSX.Element => {
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  );
  const { t } = useTranslation();
  const handleSelect = (questionId: number, question: string) => {
    if (questionId === selectedQuestionId) {
      setSelectedQuestionId(null);
    } else {
      trackFaqItemOpened({ question });
      setSelectedQuestionId(questionId);
    }
  };

  return (
    <div id="faq">
      <SectionHeader
        title={t('component.faq.title', 'Frequently asked questions')}
        titleClass="p-h2"
      />
      <FAQPageJsonLd mainEntity={faqJsonLd} />
      <div className="mt32">
        {questions.map(({ title, questionInfo }, index) => (
          // WARNING: Using index as key here - this is okay because it's static content
          <Fragment key={title ?? String(index)}>
            {title && <div className="mt48 mb24 p-h3 tc-grey-500">{title}</div>}
            {questionInfo.map(({ question, questionId, answer }) => (
              <div
                className={`mt24 ${styles['question-container']}`}
                key={question}
              >
                <div
                  role="button"
                  tabIndex={0}
                  className={`c-pointer ${styles['question-header-container']}`}
                  aria-expanded={
                    selectedQuestionId === questionId ? 'true' : 'false'
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleSelect(questionId, question);
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelect(questionId, question);
                  }}
                >
                  <h3 className="p-h4 tc-grey-700">{question}</h3>
                  <Image
                    className={classnames({
                      [styles['chevron-animation']]:
                        questionId === selectedQuestionId,
                    })}
                    src={chevronDownIcon}
                    alt="chevron"
                    width={12}
                    height={8}
                  />
                </div>
                <AnimateHeight
                  duration={300}
                  height={selectedQuestionId === questionId ? 'auto' : 0}
                >
                  {typeof answer === 'string' ? (
                    <Markdown className={markdownClassName}>{answer}</Markdown>
                  ) : (
                    answer
                  )}
                </AnimateHeight>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
      {children && <div className="mt32">{children}</div>}
    </div>
  );
};

export default FAQSection;
