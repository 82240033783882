import { urlWithStoredTrackingObject } from '@getpopsure/tracker';
import { urlSearchParamToTrackingObject } from '@getpopsure/tracker/dist/trackingObject';
import {
  FEATHER_DOMAIN,
  FEATHER_INTERNAL_TRACKING_TOOL,
} from 'constants/cookies';
import { getCookie } from 'cookies-next';

// Append utm_source, utm_medium, utm_campaign, utm_term, utm_content to given href
export const getUrlWithTracker = (href: string) => {
  // Get UTM values from URL
  const params = new URLSearchParams(window.location.search);
  const trackingObject = urlSearchParamToTrackingObject(params);
  if (trackingObject) {
    const newLink = urlWithStoredTrackingObject(href, {
      [FEATHER_INTERNAL_TRACKING_TOOL]: JSON.stringify(trackingObject),
    });
    return newLink;
  }

  // Alternatively, get UTM values from cookie
  const utmCookieValues = getCookie(FEATHER_INTERNAL_TRACKING_TOOL, {
    domain: FEATHER_DOMAIN,
  });
  if (utmCookieValues) {
    const newLink = urlWithStoredTrackingObject(href, {
      [FEATHER_INTERNAL_TRACKING_TOOL]: utmCookieValues as string,
    });
    return newLink;
  }

  return href;
};
