import { RichTextField } from '@prismicio/client';
import { asText } from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import CtaLink from 'components/CtaLink';
import Markdown from 'components/markdown';
import { Styling } from 'components/markdown/styling';
import { ModalLink } from 'components/ModalLink/ModalLink';
import React from 'react';

import styles from './PrismicRichText.module.scss';

export const PrismicRichTextComponent = ({
  richText,
  paragraphClassName,
  styling,
}: {
  richText: RichTextField;
  paragraphClassName?: string;
  styling?: Partial<Styling>;
}) => (
  <PrismicRichText
    field={richText}
    components={{
      heading1: ({ children }) => <h1 className="p-h1">{children}</h1>,
      heading2: ({ children }) => <h2 className="p-h2">{children}</h2>,
      heading3: ({ children }) => <h3 className="p-h3">{children}</h3>,
      paragraph: ({ children }) => (
        <p className={paragraphClassName ?? 'p-p mt8'}>{children}</p>
      ),
      strong: ({ children }) => <span className="fw-bold">{children}</span>,
      em: ({ children }) => <span className="fs-italic">{children}</span>,
      hyperlink: ({ node: { data }, children }) => {
        if (data.link_type === 'Document' && data.type === 'modal') {
          return <ModalLink uid={data.uid ?? ''}>{children}</ModalLink>;
        }
        return (
          <CtaLink
            className="p-a fw-bold"
            href={data.url ?? ''}
            target={data.link_type === 'Web' ? data.target : '_self'}
          >
            {/* RichText serializes children as string */}
            {children as unknown as string}
          </CtaLink>
        );
      },
      list: ({ children }) => (
        <ul className={`p-p mt8 ${styles.ulist}`}>{children}</ul>
      ),
      preformatted: () => {
        return <Markdown styling={styling}>{asText(richText) ?? ''}</Markdown>;
      },
      oList: ({ children }) => (
        <ol className={`p-p mt8 ${styles.olist}`}>{children}</ol>
      ),
    }}
  />
);
