import { insurance } from '@getpopsure/private-constants';
import { generateQueryPath } from 'util/liability-insurance';

// All CTA links from CMS should go through this resolver function, so that relevant params are added
export const ctaLinkResolver = (
  href: string,
  option?: Record<string, string>
) => {
  if (href === insurance.liability.signup && option?.liability) {
    return generateQueryPath(option.liability);
  }
  return href;
  // ... and more cases to be added in the future
};
