import React, { createContext, ReactNode, useContext, useState } from 'react';

const ModalContext = createContext({
  openModal: '',
  setOpenModal: (value: string) => {},
});

export const ModalWrapper = ({ children }: { children: ReactNode }) => {
  const [openModal, setState] = useState<string>('');

  // This step is needed because we cannot use React setState for createContext initial value
  const setOpenModal = (modalContext: string) => {
    setState(modalContext);
  };

  return (
    // TODO: bring this back in [EPIC-1083]
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ openModal, setOpenModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
