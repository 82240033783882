import { faq } from '@getpopsure/private-constants';
import FAQSection, { QuestionInfo, QuestionSection } from 'components/faq';
import Markdown from 'components/markdown';
import MissingRequiredFields from 'components/MissingRequiredFields';
import PrismicRichText from 'components/PrismicRichText';
import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  FaqSectionSlice,
  FaqSectionSliceFaqWithSectionsItem,
} from 'types.generated';

import styles from './FaqSection.module.scss';

export const FaqSection = ({ slice }: { slice: FaqSectionSlice }) => {
  const { has_faq_page_link: hasFaqPageLink } = slice.primary;

  const { t } = useTranslation();
  const validFaqs = slice.items.filter(
    (item) => !!item.question && item.answer.length > 0
  );
  if (validFaqs.length === 0) return <MissingRequiredFields />;
  let questions: QuestionSection[] = [];

  if (slice.variation === 'default') {
    questions = [
      {
        questionInfo: validFaqs.map((item, idx) => ({
          question: item.question as string,
          answer: <PrismicRichText richText={item.answer} />,
          questionId: idx,
        })),
      },
    ];
  } else if (slice.variation === 'faqWithSections') {
    const questionByCategory: Record<string, QuestionInfo[]> = {};
    (validFaqs as FaqSectionSliceFaqWithSectionsItem[]).forEach((item, idx) => {
      if (!item.category_name) return;
      if (!questionByCategory[item.category_name]) {
        questionByCategory[item.category_name] = [
          {
            question: item.question as string,
            answer: <PrismicRichText richText={item.answer} />,
            questionId: idx,
          },
        ];
      } else {
        questionByCategory[item.category_name].push({
          question: item.question as string,
          answer: <PrismicRichText richText={item.answer} />,
          questionId: idx,
        });
      }
    });
    questions = Object.keys(questionByCategory).map((category) => ({
      title: category,
      questionInfo: questionByCategory[category],
    }));
  }

  const faqJsonLd = questions
    .flatMap(({ questionInfo }) => questionInfo)
    .map(({ question, answer }) => ({
      questionName: question,
      acceptedAnswerText:
        typeof answer === 'string' ? answer : answer.props.richText[0].text,
    }));

  return (
    <section className={`mt56 p-body ${styles.container} `}>
      <FAQSection questions={questions} faqJsonLd={faqJsonLd}>
        {hasFaqPageLink && (
          <Markdown>
            {t('component.faq.faqPage.link', {
              faqLink: faq.base,
            })}
          </Markdown>
        )}
      </FAQSection>
    </section>
  );
};
