import { ReactNode } from 'react';

import { useModalContext } from '../../context';

export const ModalLink = ({
  uid,
  children,
}: {
  uid: string;
  children: ReactNode;
}) => {
  const { setOpenModal } = useModalContext();

  return (
    <button
      type="button"
      onClick={() => setOpenModal(uid)}
      className="p-a fw-bold c-pointer bg-transparent"
    >
      {children}
    </button>
  );
};
