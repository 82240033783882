import { Styling } from '.';
import styles from './default.module.scss';

export const defaultStyling: Styling = {
  a: 'p-a',
  p: 'p-p mt8',
  h1: 'p-h1 p--serif mt32',
  h2: 'p-h2 mt24',
  h3: 'p-h3 mt16',
  h4: 'p-h4 mt8',
  ol: `p-p ${styles.olist} mt8`,
  ul: `p-p ${styles.ulist} mt8`,
  strong: 'fw-bold',
  em: 'fs-italic',
  table: `w100 mt24 ${styles.table}`,
  th: 'p-h4',
  tr: styles.row,
  td: 'px8 py8 tc-grey-700',
  blockquote: 'bg-grey-200 my24 px24 pt16 pb24 br8',
  code: styles.code,
  pre: `d-block tc-grey-200 bg-grey-900 px16 py16 my16 br8 ${styles.pre}`,
};
