import { InclusiveMember } from '@getpopsure/liability-insurance-pricing-engine';
import { insurance } from '@getpopsure/private-constants';
import { inclusiveMembersValue } from 'content/liability-insurance/inclusiveMembersList';

const inclusiveMembersKey = 'liability_inclusive_member';

export const setInclusiveMembersInLocalStorage = (
  inclusiveMembers: InclusiveMember[]
) => {
  localStorage.setItem(inclusiveMembersKey, inclusiveMembers.join(','));
  window.dispatchEvent(new Event('storage'));
};

export const getInclusiveMembersInLocalStorage = () => {
  const value = localStorage.getItem(inclusiveMembersKey);
  return value ?? '';
};

export const generateQueryPath = (inclusiveMembers: string) => {
  const inclusiveMembersArr = inclusiveMembers.split(',');
  if (
    inclusiveMembersArr.every((value) =>
      inclusiveMembersValue.includes(value as InclusiveMember)
    )
  ) {
    return `${insurance.liability.signup}?inclusiveMembers=${inclusiveMembers}`;
  }
  return insurance.liability.signup;
};
