import classNames from 'classnames';
import React from 'react';

import styles from './style.module.scss';

// should be eventually replaced by SectionHeader slice

export const SectionHeader = ({
  title,
  titleClass,
  alignment = 'left',
  greyUnderline,
  centeredOnMobile = false,
}: {
  title: string;
  titleClass: string;
  alignment?: 'center' | 'left';
  greyUnderline?: boolean;
  centeredOnMobile?: boolean;
}) => (
  <div
    className={classNames({
      'd-flex fd-column ai-center': alignment === 'center',
      [styles.mobileCenter]: centeredOnMobile,
    })}
  >
    <h2 className={`p--serif ${titleClass}`}>{title}</h2>
    <div
      data-aos="animate-underline"
      data-aos-once="true"
      className={classNames(styles.separator, {
        [styles.separatorGreyUnderline]: greyUnderline,
      })}
    />
  </div>
);
