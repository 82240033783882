import { InclusiveMember } from '@getpopsure/liability-insurance-pricing-engine';
import { TFunction } from 'next-i18next';

export const inclusiveMembersInfo: (t: TFunction) => Array<{
  title: string;
  value: InclusiveMember;
}> = (t: TFunction) => [
  {
    title: t('page.liability.section.calculate.members.partner', 'Partner'),
    value: 'PARTNER',
  },
  {
    title: t('page.liability.section.calculate.members.kid', 'Kid(s)'),
    value: 'KIDS',
  },
  {
    title: t('page.liability.section.calculate.members.parent', 'Parent(s)'),
    value: 'PARENTS',
  },
];

export const inclusiveMembersValue: InclusiveMember[] = [
  'PARTNER',
  'KIDS',
  'PARENTS',
];
